<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-postulacion-export"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar Listado Postulaciones</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="">Fecha Inicial</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_ini"
                        :class="
                          $v.filtros.fecha_ini.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="">Fecha Final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_fin"
                        :class="
                          $v.filtros.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-5">Tipo Combustible</label>
                      <v-select
                        :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                        class="form-control form-control-sm p-0 col-md-7"
                        v-model="tipo_combustible"
                        placeholder="Combustible"
                        label="descripcion"
                        :options="listasForms.tiposCombustible"
                        @input="seleccionarTipoCombustible()"
                      ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="empresa_id" class="col-md-5">Empresa</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="empresa"
                      placeholder="Empresas"
                      label="razon_social"
                      class="p-0 col-md-7"
                      :options="listasForms.empresas"
                      :filterable="true"
                      @input="getSelectEmpresas()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="campo_id" class="col-md-5">Campo</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="campo"
                      placeholder="Campo"
                      label="nombre"
                      class="p-0 col-md-7"
                      :options="listasForms.sitios"
                      :filterable="true"
                      @input="getSelectCampos()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="tipo_ruta" class="col-md-5">Tipo Ruta</label>
                    <select
                      id="tipo_ruta"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_ruta"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo in listasForms.tipo_ruta"
                        :key="tipo.numeracion"
                        :value="tipo.numeracion"
                      >
                        {{ tipo.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-show="!$v.filtros.$invalid"
                v-if="
                  $store.getters.can('hidrocarburos.reportes.postulacionExport')
                "
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "PostulacionExport", //llegada tambien
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      empresa: {},
      campo: {},
      tipo_combustible: [],
      //SE CREAN LOS CHECKLIST PARA EL BACK
      //SE CREAN FILTROS PARA EL BACK
      filtros: {
        fecha: null,
        empresa_id: null,
        campo_id: null,
        tipo_ruta: null,
      },
      //OBTIENE LAS LISTAS DE LOS CORRESPONDIENTES A LAS EMPRESAS
      listasForms: {
        empresas: [],
        sitios: [],
        tipo_ruta: [],
        tiposCombustible: [],
      },
    };
  },
  validations: {
    filtros: {
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
    },
  },
  methods: {
    validaFechas() {
      if (this.filtros.fecha_ini) {
        const hoy = new Date();
        const fecha_inicial = new Date(this.filtros.fecha_ini);
        fecha_inicial.setDate(fecha_inicial.getDate());

        if (fecha_inicial >= hoy) {
          this.filtros.fecha_ini = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fecha_ini && this.filtros.fecha_fin) {
        var fecha_menor = new Date(this.filtros.fecha_ini);
        var fecha_mayor = new Date(this.filtros.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      /* if (this.filtros.fecha_fin) {
        var hoy = new Date();
        const fecha_final = new Date(this.filtros.fecha_fin);
        fecha_final.setDate(fecha_final.getDate());
        if (fecha_final >= hoy) {
          this.filtros.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha final debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } */
    },

    getSelectEmpresas() {
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
    },

    getSelectCampos() {
      this.filtros.campo_id = null;
      if (this.campo) {
        this.filtros.campo_id = this.campo.id;
      }
    },

    seleccionarTipoCombustible() {
      this.filtros.tipo_combustible = this.tipo_combustible ? this.tipo_combustible.numeracion : null;
    },

    //MANDA A LLAMAR LA FUNCION EXPORT EN EL BACK
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/postulaciones/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          let data = response.data;
          if (!response.data.error) {
            location.href = data.url;
          } else {
            this.$swal({
              icon: "error",
              title: response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
};
</script>
